import { Col, DatePicker, InputNumber, Modal, Row } from 'antd';
import dayjs from 'dayjs';
import { Button } from 'react-bootstrap';

import useCalculator from '../../hooks/useCalculator';

import { ICalculatorProps } from './ICalculatorProps';

import './Calculator.scss';

const Calculator = ({ userInstitutionName, setModalCalculatorVisible, modalCalculatorVisible }: ICalculatorProps) => {
	const {
		commitment,
		signatureDate,
		dateFormat,
		commission,
		equalizationValue,
		capitalCall,
		totalCapitalCall,
		handleCloseModal,
		handleChangeCommitment,
		handleChangeSignatureDate
	} = useCalculator({
		userInstitutionName,
		setModalCalculatorVisible
	})

	return (
		<div>
			<Modal
				title="Simulación de cálculo de intereses compensatorios/ Ecualización"
				open={modalCalculatorVisible}
				width={600}
				onOk={() => handleCloseModal()}
				onCancel={() => handleCloseModal()}
				footer={[
					<Row>
						<Col span={20} >
							<b>Nota:</b> A las participaciones de tipo B, será necesario sumarle un 1% del Compromiso de Inversión en concepto de comisión de suscripción.
						</Col>
						<Col span={4} >
							<Button onClick={() => handleCloseModal()}>Cerrar</Button>
						</Col>
					</Row>
				]}
			>
				<Row>
					<Col span={24} className='Title'>
						FoF VI
					</Col>
				</Row>
				<Row>
					<Col span={8}> Compromiso: </Col>
					<Col span={4}></Col>
					<Col span={8}> 
						<InputNumber 
							onChange={handleChangeCommitment}
							value={commitment}
							style={{width:'100%'}}
							formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
							parser={(value:any) => value.replace(/\\s?|(,*)/g, '')}
						/>
					</Col>
				</Row>
				<br />
				<Row>
					<Col span={8}> Fecha de cierre de cliente: </Col>
					<Col span={4}></Col>
					<Col span={8}>
						<DatePicker
							defaultValue={signatureDate ? dayjs(signatureDate, dateFormat) : undefined}
							format={dateFormat}
							onChange={handleChangeSignatureDate}
						/>
					</Col>
				</Row>
				<br />
				<Row>
					<Col span={8}> Comisión de suscripción: </Col>
					<Col span={4}></Col>
					<Col span={8}> {`€ ${commission}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} </Col>
				</Row>
				<br />
				<Row>
					<Col span={8}> Importe de ecualización: </Col>
					<Col span={4}></Col>
					<Col span={8} className='borderBottom'> {`€ ${equalizationValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} </Col>
				</Row>
				<br/>
				<Row>
					<Col span={8}> Desembolso de capital: </Col>
					<Col span={3}>
						<InputNumber
							defaultValue={30}
							min={0}
							max={100}
							formatter={(value) => `${value}%`}
							parser={(value: any) => value.replace('%', '')}
							/* onChange={(e) => setPercent(e)} */
							style={{width:'100%'}}
							disabled
						/>
					</Col>
					<Col span={1}></Col>
					<Col span={8} className='borderBottom'> {`€ ${capitalCall}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} </Col>
				</Row>
				<br/>
				<Row>
					<Col span={8}> Desembolso total: </Col>
					<Col span={4}></Col>
					<Col span={8} className='borderBottom'> {`€ ${totalCapitalCall}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} </Col>
				</Row>
				<br />
			</Modal>
		</div>
	);
};

export default Calculator;
