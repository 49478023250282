import { AxiosResponse } from 'axios';

import API from './api';

import { IAPI } from './IAPI';
import { Vehicle } from '../Models/Vehicle.model';

const VehicleAPI = ({ baseUrl, getAuthToken, showServiceFeedbackModal }: IAPI) => {
    const api = API(baseUrl!, getAuthToken);

    const getVehicles = (SysInstitutionId: number): Promise<Vehicle[]> => {
        const serviceUrl = '/api/v1/BookBuildingVehicle';
        return api.get(serviceUrl, { params: { SysInstitutionId } })
            .then((response: AxiosResponse) => response.data)
            .catch((error) => showServiceFeedbackModal(error.response.status, `${baseUrl}${serviceUrl}`));
    };

    return {
        getVehicles
    };
};

export default VehicleAPI;