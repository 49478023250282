import React, { useEffect } from 'react';
import { Modal } from 'antd';
import { PowerBIEmbed } from 'powerbi-client-react';
import { Embed, models } from 'powerbi-client';

import PowerBIAPI from '../../api/PowerBIAPI';

import { IPowerBIProps } from './IPowerBIProps';

import './PowerBI.css';

const PowerBIReport = ({
	setPowerBIVisible,
    PowerBIVisible,
    PowerBiId,
    baseUrl,
    getAuthToken,
    showServiceFeedbackModal,
}: IPowerBIProps) => {
	const [powerBIToken, setPowerBIToken] = React.useState("");

	useEffect(() => {
		const api = PowerBIAPI({
			baseUrl,
			getAuthToken,
			showServiceFeedbackModal
		})
		api.getBIToken().then((token: any) => {
			setPowerBIToken(token)
		});
	}, []);

	return (
		<Modal
			title="Gestión de cartera"
			open={PowerBIVisible}
			onOk={() => setPowerBIVisible(false)}
			onCancel={() => setPowerBIVisible(false)}
			width={'1350px'}
			centered
			footer={[]}
		>
			<PowerBIEmbed
				embedConfig={{
					type: 'report',
					id: PowerBiId,
					embedUrl: 'https://app.powerbi.com/reportEmbed',
					accessToken: powerBIToken,
					tokenType: models.TokenType.Aad,
					settings: {
						panes: {
							filters: {
								expanded: false,
								visible: false
							},
						},
						navContentPaneEnabled:false,
						background: models.BackgroundType.Transparent,
					}
				}}
				eventHandlers={
					new Map([
						['loaded', function () { console.log('Report loaded'); }],
						['rendered', function () { console.log('Report rendered'); }],
						['error', function (event: any) { console.log(event.detail); }]
					])
				}
				cssClassName={"report-style-class"}
				getEmbeddedComponent={(embedObject:Embed) => {
					console.log(`Embedded object of type "${ embedObject.embedtype }" received`);
				}}
			/>
		</Modal>
	);
};

export default PowerBIReport;
