import axios from "axios";

const API = (baseURL: string, getAuthToken: () => string) => {
  	const AxiosAPI = axios.create({
		baseURL,
		headers: {
			'Access-Control-Allow-Origin' : '*',
			'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
		}
  	});

  	AxiosAPI.interceptors.request.use(function (config) {
		if(config?.headers){
			config.headers['Authorization'] = `Bearer ${getAuthToken()}`;
		}
		return config;
  	});

  	return AxiosAPI;
};

export default API;
