import { Button, Col, Form, Row } from 'react-bootstrap';
import { Modal } from 'antd';
import CheckCircleOutlined from '@ant-design/icons/lib/icons/CheckCircleOutlined';

import INewEventModal from './INewEventModal';

const NewEventModal = (props: INewEventModal) => (
	<Modal
		title={"Nueva reunión"}
		open={props.isOpen}
		onOk={() => props.close()}
		onCancel={() => props.close()}
		width={"50%"}
		footer={[]}
	>
		<Form validated onSubmit={props.saveEvent}>
			<Form.Group controlId="subject">
				<Form.Label>Asunto</Form.Label>
				<Form.Control
					type="text"
					name="subject"
					id="subject"
					required
					className="mb-2"
				/>
			</Form.Group>
			<Form.Group controlId="attendees">
				<Form.Label>Asistentes:</Form.Label>
				<div style={{color:'gray'}}><CheckCircleOutlined/>&nbsp;El Equipo de Qualitas Funds</div>
				<br/>
				<Form.Control
					type="text"
					name="attendees"
					id="attendees"
					required
					className="mb-2"
					placeholder="Ingrese otras direcciones de correo, separadas por punto y coma (;)"
				/>
			</Form.Group>
			<Row className="mb-2">
				<Col>
					<Form.Group controlId="start">
						<Form.Label>Inicio</Form.Label>
						<Form.Control
							type="datetime-local"
							name="start"
							id="start"
							required
						/>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group controlId="end">
						<Form.Label>Fin</Form.Label>
						<Form.Control
							type="datetime-local"
							name="end"
							id="end"
							required
						/>
					</Form.Group>
				</Col>
			</Row>
			<Form.Group controlId="body">
				<Form.Label>Detalles de la reunión</Form.Label>
				<Form.Control
					as="textarea"
					name="body"
					id="body"
					required
					className="mb-3"
					style={{ height: '10em' }}
				/>
			</Form.Group>
			<Button color="primary"
				className="me-2"
				type="submit"
			>
				Create
			</Button>
			<Button
				className="btn btn-secondary"
				onClick={() => props.close()}
			>
				Cancel
			</Button>
		</Form>
	</Modal>
);
export default NewEventModal;
