const LegalDocSubmenu = (props: any) => (
	<>
		{props.fund === 'FCR VI' &&
			<>
				<div className='itemMenu' onClick={() => 
					props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Documentación Legal/FCR VI/FCR VI Folleto + Reglamento 02112023.pdf')
				}>Folleto y Reglamento</div>
				<div className='itemMenu' onClick={() => 
					props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Documentación Legal/FCR VI/DFIs/Binded DFIs_FCR_VI.pdf')
				}>DFI</div>
			</>
		}
		{props.fund === 'SCR VI A' &&
			<>
				<div className='itemMenu' onClick={() => 
					props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Documentación Legal/SCR VI A/Acuerdo Accionistas SCR VI A.pdf')
				}>Acuerdo de Accionistas</div>
				<div className='itemMenu' onClick={() => 
					props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Documentación Legal/SCR VI A/CodigosISIN VI A.pdf')
				}>Códigos ISIN</div>
				<div className='itemMenu' onClick={() => 
					props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Documentación Legal/SCR VI A/Estatutos Sociales SCR VI A.pdf')
				}>Estatutos Sociales</div>
				<div className='itemMenu' onClick={() => 
					props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Documentación Legal/SCR VI A/Folleto SCR VI A+ Sostenb.pdf')
				}>Folleto y Reglamento</div>
				<div className='itemMenu' onClick={() => 
					props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Documentación Legal/SCR VI A/DFIs/DFIs Binded_SCR A_VI.pdf')
				}>DFI</div>
			</>
		}
		{props.fund === 'SCR VI B' &&
			<>
				<div className='itemMenu' onClick={() => 
					props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Documentación Legal/SCR VI B/Acuerdo Accionistas SCR VI B.pdf')
				}>Acuerdo de Accionistas</div>
				<div className='itemMenu' onClick={() => 
					props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Documentación Legal/SCR VI B/CodigosISIN VI B.pdf')
				}>Códigos ISIN</div>
				<div className='itemMenu' onClick={() => 
					props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Documentación Legal/SCR VI B/Estatutos Sociales SCR VI B.pdf')
				}>Estatutos Sociales</div>
				<div className='itemMenu' onClick={() => 
					props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Documentación Legal/SCR VI B/Folleto SCR VI B+ anexo.pdf')
				}>Folleto y Reglamento</div>
				<div className='itemMenu' onClick={() => 
					props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Documentación Legal/SCR VI B/DFIs/Binded DFIs_SCR B_VI.pdf')
				}>DFI</div>
			</>
		}
		{props.fund === 'SCR VI C' &&
			<>
				<div className='itemMenu' onClick={() => 
					props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Documentación Legal/SCR VI C/Acuerdo Accionistas SCR VI C.pdf')
				}>Acuerdo de Accionistas</div>
				<div className='itemMenu' onClick={() => 
					props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Documentación Legal/SCR VI C/CodigosISIN VI C.pdf')
				}>Códigos ISIN</div>
				<div className='itemMenu' onClick={() => 
					props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Documentación Legal/SCR VI C/Estatutos Sociales VI SCR C.pdf')
				}>Estatutos Sociales</div>
				<div className='itemMenu' onClick={() => 
					props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Documentación Legal/SCR VI C/Folleto+Anexo VI SCR C.pdf')
				}>Folleto y Reglamento</div>
				<div className='itemMenu' onClick={() => 
					props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Documentación Legal/SCR VI C/DFIs/DFIs Binded_SCR C_VI.pdf')
				}>DFI</div>
			</>
		}
		{props.fund === 'SCR VI D' &&
			<>
				<div className='itemMenu' onClick={() => 
					props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Documentación Legal/SCR VI D/Acuerdo Accionistas SCR VI D.pdf')
				}>Acuerdo de Accionistas</div>
				<div className='itemMenu' onClick={() => 
					props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Documentación Legal/SCR VI D/CodigosISIN VI D.pdf')
				}>Códigos ISIN</div>
				<div className='itemMenu' onClick={() => 
					props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Documentación Legal/SCR VI D/Estatutos Sociales SCR VI D.pdf')
				}>Estatutos Sociales</div>
				<div className='itemMenu' onClick={() => 
					props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Documentación Legal/SCR VI D/Folleto+Anexo SCR VI D.pdf')
				}>Folleto y Reglamento</div>
				<div className='itemMenu' onClick={() => 
					props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Documentación Legal/SCR VI D/DFIs/DFIs Binded_SCR D_VI.pdf')
				}>DFI</div>
			</>
		}
	</>
);

export default LegalDocSubmenu;