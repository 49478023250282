import { AxiosResponse } from 'axios';

import API from './api';

import { IAPI } from './IAPI';

const PowerBIAPI = ({ baseUrl, getAuthToken, showServiceFeedbackModal }: IAPI) => {
    const api = API(baseUrl!, getAuthToken);
    
    const getBIToken = () => {
        const serviceUrl = '/api/v1/Masters/getAccessTokenSilently';
        return api.get(serviceUrl)
            .then((response: AxiosResponse) => response.data)
            .catch((error) => showServiceFeedbackModal(error.response.status, `${baseUrl}${serviceUrl}`));
    };

    return {
        getBIToken
    };
};

export default PowerBIAPI;