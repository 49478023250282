import { useState, useEffect } from "react";

import { Document } from "../Models/Document";
import { IUseUserDocuments } from "./IUseUserDocuments";

const useUserDocuments = ({ api, userInstitutionName }: IUseUserDocuments) => {
	const [documents, setDocuments] = useState<Document[]>([]);

	const getCommentsOfPortfolio = async () => {
        const response: any = await api.getUserDocuments(userInstitutionName);
		setDocuments(response);
    };

    useEffect(() => {
		getCommentsOfPortfolio();
    }, [userInstitutionName]);

    return { documents };
};

export default useUserDocuments;
