import { Popover } from 'antd';

import useUserPresence from '../../hooks/useUserPresence';

import { IPresenceProps } from './IPresenceProps';

import './Presence.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const Presence = (props: IPresenceProps) => {
    const { callUser, getUsersAvailable, isSomeUserAway } = useUserPresence();

    return (
        <>
            {getUsersAvailable().length > 0 ?
                <div onClick={callUser} className="section-btn">
                    <p>Llama ahora</p> 
                    <FontAwesomeIcon icon={faAngleRight} />
                </div>
                :
                <Popover placement="bottomLeft" content={
                    <a className="presence-phone-number" href="tel:+34 91 436 04 72">+34 91 436 04 72</a>
                }>
                    {/* <div style={{ width: 10, height: 10, backgroundColor: `${isSomeUserAway().length > 0 ? 'orange' : 'red'}`, borderRadius: 5, display: 'inline-block' }}></div>&nbsp;&nbsp; */}
                    <div className="section-btn">
                        <p>Llama ahora</p> 
                        <FontAwesomeIcon icon={faAngleRight} />
                    </div>
                </Popover>
            }
        </>
    )
}

export default Presence