import { useCallback, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";

import { IUseCalculator } from "./IUseCalculator";

const dateFormat = 'DD-MM-YYYY';
const useCalculator = ({ userInstitutionName, setModalCalculatorVisible }: IUseCalculator) => {
	const [commission,setCommission] = useState<number>(0);
	const [commitment, setCommitment] = useState<number>(0);
	const [signatureDate, setSignatureDate]: any = useState<string>(dayjs(new Date()).format(dateFormat));
	const [equalizationValue, setEqualizationValue] = useState<number>(0);
	const [capitalCall, setCapitalCall] = useState<number>(0);
	const [totalCapitalCall, setTotalCapitalCall] = useState<number>(0);

	const calculateEcualization = useCallback(() => {
		if (signatureDate) {
			const ecualizationDate = '24-12-2023';
			const secondEcualizationDate = '15-02-2024';
			const percentage = 30;
			const signatureDateDayjs = dayjs(signatureDate, 'DD-MM-YYYY');
			const ecualizationDateDayjs = dayjs(ecualizationDate, 'DD-MM-YYYY');
			const secondEqualizationDateDayjs = dayjs(secondEcualizationDate, 'DD-MM-YYYY');
			let capitalCall = (commitment * percentage) / 100;
			setCapitalCall(capitalCall);
			const diffTimeFirst = Math.abs(signatureDateDayjs.diff(ecualizationDateDayjs, 'day'));
			const diffTimeSecond = Math.abs(signatureDateDayjs.diff(secondEqualizationDateDayjs, 'day'));

			const First = ((diffTimeFirst * 0.03)) / 365 * 0.3;
			const Second = ((diffTimeSecond * 0.03)) / 365 * 0.1;

			let ecualization = 0;
			let comision = 0;
			if (signatureDateDayjs.isAfter(ecualizationDateDayjs)) {
				ecualization = (commitment * First) + (commitment * Second);
				//ecualization = (commitment * First);
			}

			if(commitment < 500000 && userInstitutionName !== 'Basa') {
				comision = commitment * 0.01;
				setCommission(comision);
			} else{
				setCommission(0);
			}

			setEqualizationValue(parseFloat(ecualization.toFixed(2)));
			setTotalCapitalCall(capitalCall + parseFloat(ecualization.toFixed(2)) + comision);
		} else {
			setEqualizationValue(0);
			setTotalCapitalCall(0);
		}
	}, [commitment, signatureDate]);
	
	useEffect(() => {
		calculateEcualization()
	}, [calculateEcualization]);

	const handleCloseModal = () => {
		setCommitment(0);
		setModalCalculatorVisible(false);
	};

	const handleChangeCommitment = (newCommitment: number | null) => setCommitment(newCommitment!);

	const handleChangeSignatureDate = (newSignatureDate: Dayjs | null) => setSignatureDate(newSignatureDate!.format(dateFormat));
	
	return {
		commitment,
		signatureDate,
		dateFormat,
		commission,
		equalizationValue,
		capitalCall,
		totalCapitalCall,
		handleCloseModal,
		handleChangeCommitment,
		handleChangeSignatureDate,
	}
};

export default useCalculator;