import { useState, useEffect } from 'react';
import { Row, Col, Tree, Modal } from 'antd';

import { IVideosProps } from './IVideosProps';

import './Videos.css';

const Videos = ({
	api,
	modalGaleriaVideosVisible,
	setModalGaleriaVideosVisible
}: IVideosProps) => {
	const [arrayVideos, setarrayVideos] = useState([]);
	const [datosVideos, setDatosVideos] = useState([]);
	const [dataSourceBackUp, setDataSourceBackUp] = useState([]);

	useEffect(() => {
		if(dataSourceBackUp.length > 0){
			selectFirstVideo();
		} else{
			getVideoList();
		}
	}, []);

	const getVideoList = () => {
		setDataSourceBackUp([]);
		api.getDataRoomVideos("VideosDataRoom").then((json: any) => {
			let arrayVideos_Es:any = [];
		
			json = json.sort(function (a: any, b: any) { return a.Orden - b.Orden })
			json.map((x:any) => {
				let el:any = {}
				el.title = x.Title;
				el.key = x.Title;
				el.url = x.URL;
				el.order = x.Orden;
				if (x.Idioma === "Español") {
					let existe = arrayVideos_Es.map(function (e: any) { return e.title; }).indexOf(el.title);
					if (existe === -1) {
						arrayVideos_Es.push(el);  
					}
				}
				return x;
			});
			setDataSourceBackUp(json)
			setDatosVideos(arrayVideos_Es);
			let arrayDocs: any = [];
			arrayDocs = json.filter((x: any) => x.Title === 'Conoce Qualitas');
			setarrayVideos(arrayDocs);
		})
		.catch((err:any)=>console.log(err))
	};

	const onSelect = (keys: any) => {
		let arrayDocs: any = [];
		arrayDocs = dataSourceBackUp.filter((x: any) => x.Title === keys[0]);
		setarrayVideos(arrayDocs);
	};

	const selectFirstVideo = () => {
		let arrayDocs: any = [];
		arrayDocs = dataSourceBackUp.filter((x: any) => x.Title === 'Conoce Qualitas');
		setarrayVideos(arrayDocs);
	}

	return (
		<div className={`videos`}>
			<Modal
				title={"Galería de videos"}
				open={modalGaleriaVideosVisible}
				onOk={() => setModalGaleriaVideosVisible(false)}
				onCancel={() => setModalGaleriaVideosVisible(false)}
				width={"1200px"}
				centered
				footer={[]}
			>
				<Row>
					<Col span={6}>
						<Tree
							defaultSelectedKeys={['Qualitas Funds Introducción']}
							onSelect={onSelect}
							treeData={datosVideos}
						/>
					</Col>
					<Col span={1} className="separador-tabla-documentos"></Col>
					<Col span={16}>
						<Row>
							{arrayVideos.map((item:any) =>
								<Col xs={24} sm={24} md={24} lg={24} xl={24} 
									style={{textAlign:'center',marginBottom:10}}
								>
									<>
										{(item.Title === 'Investor day') && (item.Orden === 1 || item.Orden === 3) ?
											<div className={'VideoTitle'}>Unfortunately, the below video is only available in original version.</div>
											: <div className={'VideoTitle'}>&nbsp;</div>}
										<iframe 
											title={item.Title}
											src={item.URL}
											allowFullScreen
											width="100%"
											frameBorder={0}
											height="400px"
										/>
									</>
								</Col>
							)}
						</Row>
					</Col>
				</Row>
			</Modal>
		</div>
	);
};

export default Videos;
