import { Routes, Route, useNavigate } from "react-router-dom";

import Login, { PrivateRoute, AuthAPI, useAuth } from '@qualitas/auth';

import PrivateContentLayout from "./PrivateContentLayout";

import 'bootstrap/dist/css/bootstrap.css';
import '../node_modules/@qualitas/auth/dist/Auth.scss';

const appName = 'Sales Support';
const App = () => {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_NEW_WEB_API;

	const authAPI = AuthAPI(baseUrl);
	const authProps = useAuth({
		api: authAPI,
		navigate,
		redirectUrl: '/',
		systemMasterName: appName,
		redirectPassResetUrl: process.env['REACT_APP_Pass_Reset']
	});

	return (
		<Routes>
			<Route path="/login" element={<Login {...authProps} appName={appName}/>}/>
			<Route element={<PrivateRoute validateAuthToken={authProps.validateAuthToken}/>}>
				<Route path="*" element={
					<PrivateContentLayout
						baseUrl={baseUrl}
						getAuthToken={authProps.getAuthToken}
						userInstitutionName={authProps.userInstitutionName}
						userIdInstitution={authProps.userIdInstitution}
						userLogo={authProps.userLogo}
						logout={authProps.logOut}
					/>
				}/>
			</Route>
		</Routes>
	);
};

export default App;
