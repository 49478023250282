import { Popover } from 'antd';

import LegalDocSubmenu from '../LegalDocSubmenu/LegalDocSubmenu';

import VehicleAPI from '../../../api/VehicleAPI';

import { useVehicles } from '../../../hooks/useVehicles';

import { ILegalDoc } from './ILegalDoc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const LegalDoc = ({
	userIdInstitution,
	baseUrl,
	getAuthToken,
	showServiceFeedbackModal,
	showDocumentViewer
}: ILegalDoc) => {
	const api = VehicleAPI({
		baseUrl,
		getAuthToken,
		showServiceFeedbackModal
	});
	const { vehiclesList } = useVehicles({
		api,
		userIdInstitution
	});

	return (
		<div>
			{vehiclesList.filter(x => x.name.includes('Qualitas Mutual Private Equity Program VI FCR')).length > 0 &&
				<Popover
					placement="left"
					title="FCR VI"
					content={
						<LegalDocSubmenu fund="FCR VI" showDocumentViewer={showDocumentViewer}/>
					}
					trigger="hover"
				>
				<div className="section-btn">
					<p>FCR VI</p> 
					<FontAwesomeIcon icon={faAngleRight} />
				</div>
				</Popover>
			}

			{vehiclesList.filter(x => x.name.includes('Qualitas Mutual Private Equity Program VI SCR A')).length > 0 &&
				<Popover
					placement="left"
					title="SCR VI A"
					content={
						<LegalDocSubmenu fund="SCR VI A" showDocumentViewer={showDocumentViewer} />
					}
					trigger="hover"
				>
				<div className="section-btn">
					<p>SCR VI A</p> 
					<FontAwesomeIcon icon={faAngleRight} />
				</div>
				</Popover>
			}

			{vehiclesList.filter(x => x.name.includes('Qualitas Mutual Private Equity Program VI SCR B')).length > 0 &&
				<Popover
					placement="left"
					title="SCR VI B"
					content={
						<LegalDocSubmenu fund="SCR VI B" showDocumentViewer={showDocumentViewer} />
					}
					trigger="hover"
				>
				<div className="section-btn">
					<p>SCR VI B</p> 
					<FontAwesomeIcon icon={faAngleRight} />
				</div>
				</Popover>
			}

			{vehiclesList.filter(x => x.name.includes('Qualitas Mutual Private Equity Program VI SCR C')).length > 0 &&
				<Popover
					placement="left"
					title="SCR VI C"
					content={
						<LegalDocSubmenu fund="SCR VI C" showDocumentViewer={showDocumentViewer} />
					}
					trigger="hover"
				>
				<div className="section-btn">
					<p>SCR VI C</p> 
					<FontAwesomeIcon icon={faAngleRight} />
				</div>
				</Popover>
			}

			{vehiclesList.filter(x => x.name.includes('Qualitas Mutual Private Equity Program VI SCR D')).length > 0 &&
				<Popover
					placement="left"
					title="SCR VI D"
					content={
						<LegalDocSubmenu fund="SCR VI D" showDocumentViewer={showDocumentViewer} />
					}
					trigger="hover"
				>
				<div className="section-btn">
					<p>SCR VI D</p> 
					<FontAwesomeIcon icon={faAngleRight} />
				</div>
				</Popover>
			}
		</div>
	);
}

export default LegalDoc;