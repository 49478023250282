import axios, { AxiosResponse } from 'axios';

import API from './api';

import { IAPI } from './IAPI';

const DocumentsAPI = ({ baseUrl, getAuthToken, showServiceFeedbackModal }: IAPI) => {
    const api = API(baseUrl!, getAuthToken);

    const getVideo = (vieoName: string, containerName: string) => {
        return new Promise((resolve, reject) => {
            const instance = axios.create();
            delete instance.defaults.headers.common['Authorization'];
            instance.get(`https://services.qualitasfunds.com/api/DataRoom?vieoName=${vieoName}&containerName=${containerName}`)
                .then((data) => data !== null ? resolve(data) : reject(null))
        });
    };

    const getDataRoomVideos = (list: string) => {
        const serviceUrl = 'https://services-dev.qualitasfunds.com/api/investors/sharepoint/getlistvideos'; 
        return axios.get(serviceUrl, { params: { list } })
            .then((response: AxiosResponse) => response.data.result)
            .catch((error) => showServiceFeedbackModal(error.response.status, `${baseUrl}${serviceUrl}`));
    };

    const getUserDocuments = (userInstitutionName: string) => {
        const serviceUrl = '/documentsystem-api/Documents/FileList';
        return api.get(serviceUrl,
            { params: { folder: userInstitutionName } }
		).then((response: AxiosResponse) => response.data)
        .catch((error) => showServiceFeedbackModal(error.response.status, `${baseUrl}${serviceUrl}`));  
    };
    /*  getUserDocuments(user: string) {
        return axios.get(`${process.env.REACT_APP_WEB_SERVICES_DOCUMENTS_URL}/Documents/Get`, { params: { folder: user } }) 
    } */

    return {
        getVideo,
        getDataRoomVideos,
        getUserDocuments
    };
}

export default DocumentsAPI;