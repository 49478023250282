const InvestmentCommitmentSubmenu = (props: any) => (
	<>
		{props.fund === 'FCR VI' &&
			<>
				<div className='itemMenu' onClick={() => props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Compromiso de inversión/FCR VI/AS_PF_FCR VI.pdf')}>Acuerdo Personas Físicas</div>
				<div className='itemMenu' onClick={() => props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Compromiso de inversión/FCR VI/AS_PJ_FCR VI.pdf')}>Acuerdo Personas Juridicas</div>
				<div className='itemMenu' onClick={() => props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Compromiso de inversión/FCR VI/Test conveniencia (preguntas).pdf')}>Preguntas Test de conveniencia</div>
			</>
		}
		{props.fund === 'VI SCR A' &&
			<>
				<div className='itemMenu' onClick={() => props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Compromiso de inversión/SCR VI A/QMPEP VI A SCR.- Carta de Adhesión PJ.pdf')}>Carta de adhesión</div>
				<div className='itemMenu' onClick={() => props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Compromiso de inversión/SCR VI A/Test conveniencia (preguntas).pdf')}>Preguntas Test de conveniencia</div>
			</>
		}
		{props.fund === 'VI SCR B' &&
			<>
				<div className='itemMenu' onClick={() => props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Compromiso de inversión/SCR VI B/QMPEP VI B SCR.- Carta de Adhesión PJ.pdf')}>Carta de adhesión</div>
				<div className='itemMenu' onClick={() => props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Compromiso de inversión/SCR VI B/Test conveniencia (preguntas).pdf')}>Preguntas Test de conveniencia</div>
			</>
		}
		{props.fund === 'VI SCR C' &&
			<>
				<div className='itemMenu' onClick={() => props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Compromiso de inversión/SCR VI C/QMPEP VI C SCR.- Carta de Adhesión PJ.pdf')}>Carta de adhesión</div>
				<div className='itemMenu' onClick={() => props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Compromiso de inversión/SCR VI C/Test conveniencia (preguntas).pdf')}>Preguntas Test de conveniencia</div>
			</>
		}
		{props.fund === 'VI SCR D' &&
			<>
				<div className='itemMenu' onClick={() => props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Compromiso de inversión/SCR VI D/Carta adhesión _SCR VI D.pdf')}>Carta de adhesión</div>
				<div className='itemMenu' onClick={() => props.showDocumentViewer('https://qualitasfunds.blob.core.windows.net/docs/Compromiso de inversión/SCR VI D/Test conveniencia (preguntas).pdf')}>Preguntas Test de conveniencia</div>
			</>
		}
	</>
);

export default InvestmentCommitmentSubmenu;