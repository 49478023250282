import { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';

import { CalendarEvent } from '../Models/CalendarEvent';
import { IUseCalendar } from './IUseCalendar';

const useCalendar = ({
	api,
}: IUseCalendar) => {
	const [events, setEvents] = useState<CalendarEvent[]>([]);
	const [isOpenNewEventModal, setIsOpenNewEventModal] = useState(false);

	const weekStart = useMemo(() => dayjs().startOf('week').toDate(), []);
  	const weekEnd = useMemo(() => dayjs().endOf('week').toDate(), []);

	useEffect(() => {
		/* const utcOffset = moment().utcOffset();
		const isoStringWeekStart = moment(weekStart).add(utcOffset, 'minutes').utc().toISOString();
		const isoStringWeekEnd = moment(weekEnd).add(utcOffset, 'minutes').utc().toISOString();
		CalendarAPI.getEvents(isoStringWeekStart, isoStringWeekEnd)
			.then(response => {
				const events = response.data.value.map((event: any) => ({
					subject: event.subject,
					body: event.body,
					start: event.start,
					end: event.end,
					attendees: event.attendees,
					onlineMeeting: {
						joinUrl: event.onlineMeeting?.joinUrl
					}
				}));
				setEvents(events);
			});	 */
	}, [weekStart, weekEnd]);

	const closeNewEventModal = () => setIsOpenNewEventModal(false);
	const openNewEventModal = () => setIsOpenNewEventModal(true);

	const saveEvent = (data: any) => {
		const attendeeEmails = data.attendees.split(';')
			.map((attendee: string) => ({
				emailAddress: {
					address: attendee
				}
			}))
			.push({
				emailAddress: {
					address: 'javier.aleman@qualitasfunds.com'
				}
			})
			.push({
				emailAddress: {
					address: 'emma.hernanz@qualitasfunds.com'
				}
			});

		const newEvent: CalendarEvent = {
			subject: data.subject,
			attendees: attendeeEmails,
			start: {
				dateTime: data.start,
				timeZone: 'UTC'
			},
			end: {
				dateTime: data.end,
				timeZone: 'UTC'
			},
			body: {
				contentType: 'text',
				content: data.body
			}
		};

		api.sendEvent(newEvent);
		closeNewEventModal();
	};

    return {
		events,
		weekStart,
		weekEnd,
		isOpenNewEventModal,
		openNewEventModal,
		closeNewEventModal,
		saveEvent
	};
};

export default useCalendar;
