import { useState, useCallback } from "react";

/* import PresenceAPI from '../api/api.presence'; */

import { User } from '../Models/User';

const useUserPresence = () => {
	// Esto no debería ser un estado, no se usa para la vista
	const [users, setUsers] = useState<User[]>([
        {
            presence: '',
            id: 'db7f094d-3e8a-4c40-803a-6dd201cb5d03',
            email:'javier.aleman@qualitasfunds.com'
        },
        {
            presence: '',
            id: 'd8228db8-2fa0-4599-bddc-989c5aa72e36',
            email:'emma.hernanz@qualitasfunds.com'
        },
    ]);

/* 	const updateUsersPresence = useCallback(() => {
        const interval = setInterval(async () => {
			let presenceUpdated = false;
			let newUsersState = [...users];
            await Promise.all(newUsersState.map(async (user, i) => {
                const response = await PresenceAPI.getUserPresence(user.id);
                if (response.data.availability !== newUsersState[i].presence) {
                    newUsersState[i].presence = response.data.availability;
                    presenceUpdated = true;
                }

                return presenceUpdated;
            }));
			presenceUpdated && setUsers(newUsersState);
        }, 10000);

        return () => clearInterval(interval);
    }, [users]); */

	const getUsersAvailable = useCallback(() => {
        return users.filter(user => user.presence === 'Available')
    }, [users]);

	const isSomeUserAway = useCallback(() => {
        return users.filter(user => user.presence === 'Away' || user.presence === 'BeRightBack');
    }, [users]);

	const callUser = () => {
		const usersAvailable = getUsersAvailable();
        if(usersAvailable.length > 0) {
            window.open(`https://teams.microsoft.com/l/call/0/0?users=${usersAvailable[0].email}`, '_blank')
        }
    };

    return { callUser, getUsersAvailable, isSomeUserAway };
};

export default useUserPresence;
