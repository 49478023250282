import { useEffect, useState } from "react";

import { Vehicle } from "../Models/Vehicle.model";
import { IUseVehicles } from "./IUseVehicles";

export const useVehicles = ({ api, userIdInstitution }: IUseVehicles) => {
    const [vehiclesList, setVehiclesList] = useState<Vehicle[]>([]);

    useEffect(() => {
		getVehicles();
	}, []);   

    const getVehicles = () => {
        api.getVehicles(userIdInstitution).then((res: Vehicle[]) => {
          	setVehiclesList(res);
        })
	};

    return{
        vehiclesList,
        getVehicles
    };
};