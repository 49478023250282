import { useState } from 'react';
import { Row, Col, Card } from 'antd';

import ModalDocComercial from './ModalDocComercial/ModalDocComercial';

import { IDocComercialProps } from './IDocComercialProps';

import './DocComercial.scss';

const DocComercial = (props: IDocComercialProps) => {
    const [modalDocComercialVisible, setModalDocComercialVisible] = useState(false);

    return (
        <div style={{ textAlign: 'center' }}>
            <Row>
                <Col xs={24} sm={24} md={12} lg={2} xl={12} className='title'>
                    <u>Teaser</u>
                    <br />  <br />
                    <img alt="screenshot 1" src="https://qualitasdataroom.blob.core.windows.net/assets/image-gallery/20211026T154248339-Screenshot_1.png"
                        onClick={() => setModalDocComercialVisible(true)}

                    /> 
                    <br /><br />
                    <u>Presentación Comercial</u>
                    <br />
                    <img alt ="screenshot 1" src="https://qualitasdataroom.blob.core.windows.net/assets/image-gallery/20211026T154248339-Screenshot_1.png" />
                    <div className='modal'>
                        <ModalDocComercial 
                            modalDocComercialVisible={modalDocComercialVisible}
                            setModalDocComercialVisible={setModalDocComercialVisible}
                        />
                    </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={2} xl={12}>
                    <Card title="Documentos Banco" style={{ width: 300 }}>
                        <p>&#8226; Documento 1</p>
                        <p>&#8226; Documento 2</p>
                        <p>&#8226; Documento 3</p>
                        <p>&#8226; Documento 4</p>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default DocComercial