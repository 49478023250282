import { Button, Table } from 'react-bootstrap';
import { Modal } from 'antd';
import dayjs from 'dayjs';

import CalendarDayRow from './CalendarDayRow/CalendarDayRow';
import NewEvent from './NewEventModal/NewEventModal';

import useCalendar from '../../hooks/useCalendar';

import ICalendarProps from './ICalendarProps';

import './Calendar.css';
import CalendarAPI from '../../api/CalendarAPI';

const Calendar = (props: ICalendarProps) => {
	const api = CalendarAPI();
	const {
		events,
		weekStart,
		weekEnd,
		isOpenNewEventModal,
		openNewEventModal,
		closeNewEventModal,
		saveEvent,
	} = useCalendar({ api });

	return (
		<>
			<Modal
				title={"Calendario"}
				open={props.calendarioVisible}
				className="calendar-modal"
				onOk={() => props.setCalendarioVisible(false)}
				onCancel={() => props.setCalendarioVisible(false)}
				width={"50%"}
				footer={[]}
			>
				<div className="mb-3">
					<h1 className="mb-3">
						{dayjs(weekStart).format('MMMM DD, YYYY')} - {dayjs(weekEnd).format('MMMM DD, YYYY')}
					</h1>
					<Button
						className="btn btn-primary btn-sm"
						onClick={() => openNewEventModal()}
					>
						Agendar Llamada
					</Button>
				</div>
				<div className="calendar-week">
					<div className="table-responsive">
						{events
							&& <Table size="sm">
								<thead>
									<tr>
										<th>Fecha</th>
										<th>Hora</th>
										<th>Evento</th>
									</tr>
								</thead>
								<tbody>
									{[0, 1, 2, 3, 4, 5, 6].map((i: number) => (
										<CalendarDayRow
											key={i}
											date={dayjs(weekStart).add(i, 'days').toDate()}
											timeFormat=''
											events={events.filter(event => dayjs(event.start.dateTime).day() === i)}
										/>
									))}
								</tbody>
							</Table>
						}
					</div>
				</div>
      		</Modal>
      		<NewEvent close={closeNewEventModal} isOpen={isOpenNewEventModal} saveEvent={saveEvent}/>
		</>
  	);
};

export default Calendar;
