import axios from 'axios';
import * as qs from 'query-string';
import dayjs from 'dayjs';

export class Utils {
  	static URL_CONFIG_FILE: string = `${window.location.origin}/assets/config.js`

  	public static getConfig(): Promise<any> {
		return new Promise((resolve, reject) => {
			try {
				axios.get(this.URL_CONFIG_FILE)
					.then((data: any) => {
						if (data !== null) {
							return resolve(data.data[0]);
						} else {
							return reject(null);
						}
					})
			} catch (error) {
				return reject(null);
			}
		})
  	}

	public static getQueryString = () => {
		const parsed = qs.parse(window.location.search);
		return parsed;
	}

	public static sortArray(key: any, order = 'asc') {
		return function innerSort(a: any, b: any) {
			if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
				// property doesn't exist on either object
				return 0;
			}

			const varA = (typeof a[key] === 'string')
				? a[key].toUpperCase() : a[key];
			const varB = (typeof b[key] === 'string')
				? b[key].toUpperCase() : b[key];

			let comparison = 0;
			if (varA > varB) {
				comparison = 1;
			} else if (varA < varB) {
				comparison = -1;
			}
			return (
				(order === 'desc') ? (comparison * -1) : comparison
			);
		};
	}

	public static setCookie(clave:string, valor:string, exp:number) {
		var d = new Date(exp * 1000)
		var expires = "expires=" + d.toUTCString();
		let cookiepath = "/";
		document.cookie = clave + "=" + valor + "; " + expires +"; path=" + cookiepath;
	}

	public static getCookie(clave:string) {
		var name = clave + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) === ' ') c = c.substring(1);
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	public static checkCookie(clave:string) {
		var key = Utils.getCookie(clave);
		if (key !== "") {
			// La cookie existe.
		} else {
			window.location.href = `/Login`;
		}
	}

	public static deleteCookie(name:string){
		document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	}

	public static parseJwt(token: any) {
		try {
			return JSON.parse(atob(token.split(".")[1]));
		} catch (e) {
			return null;
		}
	};

	public static formatDateTime(dateTime: string | undefined) {		
		if (dateTime) {
			const utcOffset = dayjs().utcOffset();
			const localDate = dayjs(dateTime).add(utcOffset, 'minutes');
			return localDate.format("hh:mm a");
		}
	};
}