import { Routes, Route } from "react-router-dom";

import FeedbackModal, { useFeedbackModal } from '@qualitas/feedback-modal';

import NavBar from './Components/NavBar/NavBar';
import SectionDocs from './Components/SectionDocs/SectionDocs';
import Home from './Components/Home/Home';

const PrivateContentLayout = ({
	baseUrl,
	getAuthToken,
	userInstitutionName,
	userLogo,
	userIdInstitution,
	logout
}: any) => {
	const feedbackModalProps = useFeedbackModal({});

	return (
		<div>
			<Routes>
				<Route path="/" element={
					<Home
						userLogo={userLogo}
						userInstitutionName={userInstitutionName}
						userIdInstitution={userIdInstitution}
						getAuthToken={getAuthToken}
						showServiceFeedbackModal={feedbackModalProps.showServiceFeedbackModal}
						baseUrl={baseUrl}
						logout={logout}
					/>
				}/>
				<Route path="/SectionDocs" element={<SectionDocs/>}/>
			</Routes>
			<FeedbackModal {...feedbackModalProps}/>
		</div>
	)
};

export default PrivateContentLayout;