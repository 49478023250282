import "./Footer.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faVimeo } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <div className="footer-qf">
      <img
        className="line-blue"
        alt="line"
        src="https://qualitasfunds.blob.core.windows.net/assets/image-gallery/blue-line.png"
      />
      <div className="footer-btns">
        <div className="fotter-items">
          <a href="">Política de Privacidad</a>
          <a href="">Política de cookies</a>
          <a href="">Disclaimer</a>
        </div>
        <div className="fotter-items">
          <p>Qualitas Funds 2023. Todos los Derechos reservados.</p>
        </div>
        <div className="fotter-items">
          <a href="https://es.linkedin.com/company/qualitas-funds" target="_blank">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="https://vimeo.com/user124622109" target="_blank">
            <FontAwesomeIcon icon={faVimeo} />
          </a>
          <a href="mailto:administration.fof@qualitasfunds.com" target="_blank">
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
