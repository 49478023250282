import dayjs from 'dayjs';
import { CalendarEvent } from '../../../Models/CalendarEvent';

import { Utils } from '../../../Utils';

import ICalendarDayRowProps from './ICalendarDayRowProps';

function DateCell(props: ICalendarDayRowProps) {
	return (
		<td className='calendar-view-date-cell' rowSpan={props.events.length <= 0 ? 1 : props.events.length}>
			<div className='calendar-view-date float-left text-right'>{dayjs(props.date).format('DD')}</div>
			<div className='calendar-view-day'>{dayjs(props.date).format('dddd')}</div>
			<div className='calendar-view-month text-muted'>{dayjs(props.date).format('MMMM, YYYY')}</div>
		</td>
	);
}

const CalendarDayRow = (props: ICalendarDayRowProps) => {
	const today = new Date();
	const rowClass = today.getDay() === props.date.getDay() ? 'table-warning' : '';

	const hasTeamsUrl = (event: CalendarEvent) => event.onlineMeeting && event.onlineMeeting.joinUrl;

	if (props.events.length <= 0) {
		return (
			<tr className={rowClass}>
				<DateCell {...props} />
				<td></td>
				<td></td>
			</tr>
		);
	}

	return (
		<>
			{props.events.map((event: any, index: Number) => (
				<tr className={rowClass} key={event.start?.dateTime}>
					{ index === 0 && <DateCell {...props}/> }
					<td className="calendar-view-timespan">
						<div>{Utils.formatDateTime(event.start?.dateTime)} - {Utils.formatDateTime(event.end?.dateTime)}</div>
					</td>
					<td>
						<div className="calendar-view-subject">{event.subject}</div>
						<div className="calendar-view-organizer">{event.organizer?.emailAddress?.name}</div>
					</td>
					{hasTeamsUrl(event) &&
						<td>
							<div 
								onClick={()=>window.open(event.onlineMeeting.joinUrl,'_blank')} 
								style={{cursor:'pointer', backgroundColor:'#6264a7',borderRadius:5,color:'white',textAlign:'center'}}
							>
								Unirse
							</div>
						</td>
					}
				</tr>
			))}
		</>
	);
};
export default CalendarDayRow;
