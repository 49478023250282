import { Row, Col, Tabs } from 'antd';
import DocComercial from './DocComercial/DocComercial';

import './SectionDocs.css';

const { TabPane } = Tabs;

const SectionDocs = () => (
    <div style={{ textAlign: 'right' }}>
        <Row>
            <Col xs={0} sm={0} md={1} lg={1} xl={1}></Col>
            <Col xs={24} sm={24} md={22} lg={22} xl={22}>
                <Tabs defaultActiveKey="1" type="card">
                    <TabPane tab="Doc. Comercial"  key="1">
                        <DocComercial/>
                    </TabPane>
                    <TabPane tab="Galería de videos" key="2"/>
                    <TabPane tab="Formación PE" key="3"/>
                </Tabs>
            </Col>
            <Col xs={0} sm={0} md={1} lg={1} xl={1}></Col>
        </Row>
    </div>
);

export default SectionDocs