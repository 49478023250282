import { Button, Modal } from 'antd';

import { IModalDocComercialProps } from './IModalDocComercialProps';

import 'antd/dist/reset.css';
import './ModalDocComercial.css';

const ModalDocComercial = (props: IModalDocComercialProps) => {

    const closeModalDoc = () => {
        props.setModalDocComercialVisible(false)

    }


    return (
        <div style={{ textAlign: 'center' }} >
            
            <Modal 
             width='900px'
                title="Teaser"
                open={props.modalDocComercialVisible}
                onCancel={closeModalDoc}
                footer={[
                    <Button key="submit" type="primary" onClick={closeModalDoc}>
                        Close
                    </Button>,
                ]}
            >
                <div className="containerModal">
                <iframe className='responsive-iframe' id="fred" title="PDF in an i-Frame" src="https://qualitasfunds.blob.core.windows.net/docs/Presentación Qualitas Funds IV Octubre 2021.pdf" scrolling="auto" ></iframe>
                </div>
             

            </Modal>


        </div>
    )
}

export default ModalDocComercial