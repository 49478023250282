import { Popover } from 'antd';

import InvestmentCommitmentSubmenu from '../InvestmentCommitmentSubmenu/InvestmentCommitmentSubmenu';

import VehicleAPI from '../../../api/VehicleAPI';

import { useVehicles } from '../../../hooks/useVehicles';

import { IInvestmentCommitment } from './IInvestmentCommitment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';

const InvestmentCommitment = ({
	userIdInstitution,
	baseUrl,
	getAuthToken,
	showServiceFeedbackModal,
	showDocumentViewer
}: IInvestmentCommitment) => {
	const api = VehicleAPI({
		baseUrl,
		getAuthToken,
		showServiceFeedbackModal
	});
	const { vehiclesList } = useVehicles({
		api,
		userIdInstitution
	});

	return (
		<div>
			{vehiclesList.filter(x => x.name.includes('Qualitas Mutual Private Equity Program VI FCR')).length > 0 &&

				<Popover placement="left" title={"FCR VI"} content={<InvestmentCommitmentSubmenu fund="FCR VI" showDocumentViewer={showDocumentViewer} />} trigger="hover">
				<div className="section-btn">
					<p>FCR VI</p> 
					<FontAwesomeIcon icon={faAnglesRight} />
				</div>
				</Popover>
			}
			{vehiclesList.filter(x => x.name.includes('Qualitas Mutual Private Equity Program VI SCR A')).length > 0 &&
				<Popover placement="left" title={"VI SCR A"} content={<InvestmentCommitmentSubmenu fund="VI SCR A" showDocumentViewer={showDocumentViewer} />} trigger="hover">
				<div className="section-btn">
					<p>VI SCR A</p> 
					<FontAwesomeIcon icon={faAnglesRight} />
				</div>
				</Popover>
			}

			{vehiclesList.filter(x => x.name.includes('Qualitas Mutual Private Equity Program VI SCR B')).length > 0 &&
				<Popover placement="left" title={"VI SCR B"} content={<InvestmentCommitmentSubmenu fund="VI SCR B" showDocumentViewer={showDocumentViewer} />} trigger="hover">
				<div className="section-btn">
					<p>VI SCR B</p> 
					<FontAwesomeIcon icon={faAnglesRight} />
				</div>
				</Popover>
			}

			{vehiclesList.filter(x => x.name.includes('Qualitas Mutual Private Equity Program VI SCR C')).length > 0 &&
				<Popover placement="left" title={"VI SCR B"} content={<InvestmentCommitmentSubmenu fund="VI SCR C" showDocumentViewer={showDocumentViewer} />} trigger="hover">
				<div className="section-btn">
					<p>VI SCR C</p> 
					<FontAwesomeIcon icon={faAnglesRight} />
				</div>
				</Popover>
			}
			{vehiclesList.filter(x => x.name.includes('Qualitas Mutual Private Equity Program VI SCR D')).length > 0 &&
				<Popover placement="left" title={"VI SCR D"} content={<InvestmentCommitmentSubmenu fund="VI SCR D" showDocumentViewer={showDocumentViewer} />} trigger="hover">
				<div className="section-btn">
					<p>VI SCR D</p> 
					<FontAwesomeIcon icon={faAnglesRight} />
				</div>
				</Popover>
			}




		</div>
	);
}

export default InvestmentCommitment;