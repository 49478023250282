import axios from 'axios';
import { message } from 'antd';

import { CalendarEvent } from '../Models/CalendarEvent';

const CalendarAPI = () => {
    const getEvents = (startDateTime: string, endDateTime: string) => {
        return axios.get(`${process.env.REACT_APP_MICROSOFT_GRAPH_API_BASE_URL}/me/calendarview?startdatetime=${startDateTime}&enddatetime=${endDateTime}`)
            .catch((err: any) => message.error(err));
    };
    const sendEvent = (event: CalendarEvent) => {
        return axios.post(`${process.env.REACT_APP_MICROSOFT_GRAPH_API_BASE_URL}/me/events`, { data: event })
            .catch((err: any) => message.error(err));
    };

    return {
        getEvents,
        sendEvent
    };
};

export default CalendarAPI;