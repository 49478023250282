import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NavBar = ({ userLogo, logout }: any) => (
  <>
    {window.location.pathname !== "/Login" && (
      <div className="qf-navbar">
        <img
          height="32px"
          alt="qualitasLogo"
          src="https://qualitasfunds.blob.core.windows.net/assets/image-gallery/blackLogo.png"
        />
        <div className="qf-navbar-user">
          {/* {userLogo && <img src={userLogo} alt="userLogo" />} */}
          <div>
            <button className="logout-btn" onClick={logout}>
              <FontAwesomeIcon fontSize={20} icon={faRightFromBracket} />
            </button>
          </div>
        </div>
      </div>
    )}
  </>
);

export default NavBar;
